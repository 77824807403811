<template>
    <div>
        <modal :name="modal_name" class="final-modal popup-modal-all-width-height popup-modal-all-width-height-b2c" transition="nice-modal-fade" :class="{'modalZindex':safariTrue}">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">PAYMENT METHOD</span>
                    <span @click="hidePaymentMethod()">
                        <i class="icon icon-close pointer text-white"></i>
                    </span>
                </div>
                <div class="general-section" style="padding: 15px 0px;">
                <div class="pl-0 pr-0 pt-0">
                    <div v-if="api_call_in_progress">
                        <div class="text-center w-100 h-100 d-flex align-items-center justify-content-center">
                            <div class="digi-table-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div class="d-form" v-else>
                        <div class="d-flex">
                        <div class="w-100 row no-gutters justify-content-between align-items-end grandTotal p-2 font-weight-bold">
                            <div class="pl-2">Grand Total</div>
                            <div class="mr-2 bg-white text-secondary grandTotalValue">
                            {{ order.total || "-" }}
                            </div>
                        </div>
                        </div>
                        <div >
                        <div class="d-flex flex-wrap mt-2">
                            <div class="col-lg-12 col-md-12 colm-sm-12">
                            <div class="d-flex justify-content-between borderBottom pb-2 mt-2">
                                <span class="font-600">Customer</span>
                                <span class="text-secondary">{{open_guest.full_name}}</span>
                            </div>
                            </div>
                        </div>
                        <div >
                            <div class="d-flex flex-wrap">
                                <div class="pt-3 col-lg-6 col-md-6 col-sm-12">
                                    <span class="text-secondary mt-1 fs-14 font-600">Payment Mode</span>
                                    <div class="mt-3">
                                        <div class="form-check form-check-inline ml-2">
                                            <label class="form-check-label">
                                            <input class="form-check-input" v-model="payment_mode" type="radio" value="CARD" required />
                                            <span class="radio-icon"></span>
                                            <span class="form-check-description font-600">Card</span>
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline ml-2">
                                            <label class="form-check-label">
                                            <input class="form-check-input" v-model="payment_mode" type="radio" value="ACH" required />
                                            <span class="radio-icon"></span>
                                            <span class="form-check-description font-600">ACH</span>
                                            </label>
                                        </div>
                                    </div>
                                    <hr class="mt-4" />
                                </div>
                            </div>
                            <div class="mt-4 contactGroup pl-5 pr-5 pb-5">
                                <span class="cash-email text-secondary font-weight-bold mt-4 mb-3 d-block">Send Order Receipt</span>
                                <span class="text-secondary fs-14">Email<sup class="text-danger">★</sup></span>
                                <input id="email" class="inputContact pt-3" v-model="open_guest.email" v-validate="'required|email'" name="recieptEmail" type="text" placeholder="Please Enter Email Id" />
                                <span class="highlightContact"></span>
                                <span class="barContact"></span>
                                <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('recieptEmail')">{{ errors.first("recieptEmail") }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg">
                    <a class="btn btn-link btn-smm text-secondary" style="text-transform: uppercase !important;font-size: 12px !important;" @click="hidePaymentMethod()">CANCEL</a>
                    <button type="button" class="btn btn-secondary btn-smm ml-4" style="box-shadow: none;" @click="completePayment()">
                        MAKE PAYMENT
                    </button>
                </div>
            </div>
            </div>
        </modal>
        <sweet-modal ref="success_modal" icon="success">
        {{ modal_msg }}
        </sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">
        {{ modal_msg }}
        </sweet-modal>
        <sweet-modal ref="payment_process" icon="success">{{modal_msg}}</sweet-modal>
    </div>
</template>
<script>
import axios from "axios";
import { SweetModal } from "sweet-modal-vue";
import { EventBus } from "./eventBus/nav-bar-event.js";
import CustomerServices from "./mixins/customer"
import { HollowDotsSpinner } from 'epic-spinners'
import printers from "./mixins/printers";
import Swal from 'sweetalert2';

export default {
    name: "PaymentMethod",
    data() {
        return {
            safariTrue: false,
            modal_msg: "",
            payment_methods: [],
            customers: null,
            open_guest: {
                full_name:'',
                email: ''
            },
            payment_mode: "CARD",
            customer_email : null,
            api_call_in_progress: false,
            order_data: {
                total: 0,
                type: ''
            }
        };
    },
    mixins: [CustomerServices,printers],
    props: ["modal_name","order", "from_screen", "customer_name"],
    components: {
        SweetModal,
        HollowDotsSpinner,
    },
    methods: {
        hidePayModal(){
            this.$modal.hide("paymentModal");
        },
        isNumber: function(evt,index) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        completePayment() {
            this.$validator.validate().then(result => {
                if (result == true) {
                    this.completePaymentConfirm()
                }
            });
        },
        completePaymentConfirm() {
            try {
                this.$http.get("/cashier/is_cashier_session_active").then(res => {
                this.modal_msg = res.data.reason;
                if (res.data.status_id == 1) {
                    console.log(this.order)
                    if(this.payment_mode == 'CARD') {
                        if(this.$route.path.startsWith('/view_customer_order')) {
            		        this.$router.push(`/place_order/payment_screen?order_id=${this.$route.params.id}&order_type=${this.order.type}&total_amount=${this.order.total}`)
                        } else {
            		        this.$router.push(`/place_order/payment_screen?order_id=${this.$route.query.order_id}&order_type=${this.order.type}&total_amount=${this.order.total}`)
                        }
                    } else {
                        if(this.$route.path.startsWith('/view_customer_order')) {
            		        this.$router.push(`/place_order/payment_screen_ach?order_id=${this.$route.params.id}&order_type=${this.order.type}&total_amount=${this.order.total}`)
                        } else {
            		        this.$router.push(`/place_order/payment_screen_ach?order_id=${this.$route.query.order_id}&order_type=${this.order.type}&total_amount=${this.order.total}`)
                        }
                    }
                } else {
                    this.$toasted.global.error(res.data.reason);
            		// this.is_cashier_session_active_take_order = false;
            		// this.cashier_session_error_msg = res.data.reason;
                }
                });
            } catch (reason) {
                this.$toasted.global.error(reason);
            }
        },
       
        getOpenGuest() {
            this.api_call_in_progress = true
            this.$http.post("customers/get_guest_customer_id", {

            }).then(response => {
                this.open_guest = {
                    _id: response.data.open_guest_id._id,
                    full_name: response.data.open_guest_id.full_name,
                    last_name: "",
                    email: response.data.open_guest_id.email
                };
                this.api_call_in_progress = false
                console.log(this.open_guest)
            }).catch((error) => {
                console.log(error)
                this.api_call_in_progress = false
            });
        },
        hidePaymentMethod() {
            this.$emit('closePayment')
            // if(this.fastPay == false){
            //     this.splitBill = false;
            //     this.splitEqually = false;
            //     this.payment.cash_returned = 0;
            //     this.cash_tendered = 0;
            //     this.summary.payments[0].mode = 'CASH';
            //     this.total_cash = 0;
            //     this.splitType = "AMOUNT";
            //     this.summary.payments[0].card_provider = "";
            //     this.summary.payments[0].name_on_card = "";
            //     this.summary.payments[0].bank_name = "";
            //     this.summary.payments[0].approval_code = "";
            //     this.summary.payments[0].card_type = "";
            //     this.summary.payments[0].card_number = "";
            //     this.summary.payments[0].transaction_id = "";
            //     this.summary.payments[0].payment_method = "";
            //     this.summary.payments[0].reference_id = "";
            //     this.$emit("close");
            // }
            // else{
            //     this.splitBill = false;
            //     this.splitEqually = false;
            //     this.payment.cash_returned = 0;
            //     this.cash_tendered = 0;
            //     this.summary.payments[0].mode = 'CASH';
            //     this.total_cash = 0;
            //     this.splitType = "AMOUNT";
            //     this.summary.payments[0].card_provider = "";
            //     this.summary.payments[0].name_on_card = "";
            //     this.summary.payments[0].bank_name = "";
            //     this.summary.payments[0].approval_code = "";
            //     this.summary.payments[0].card_type = "";
            //     this.summary.payments[0].card_number = "";
            //     this.summary.payments[0].transaction_id = "";
            //     this.summary.payments[0].payment_method = "";
            //     this.summary.payments[0].reference_id = "";
            //     this.$emit("close");
            //     EventBus.$emit('loadProductList');
            //     this.$modal.hide(this.modal_name)
            // }
        },
        async getSearchCustomer(search = "") {
            try {
                let params = {
                            "properties": ["first_name", "last_name", "full_name"],
                            "search": { term: search, fields: ["full_name"] },
                            "sort": { by: "full_name", order: 1 },
                            "skip": 0,
                            "limit": 5,
                        }
                let res = await this.customerGetCustomerWith(params)
                this.customers = res.response
            } catch (reason) {
                // TODO: Handle Error
            }
        },
    },
    computed:{
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        fcmDetails() {
            return this.$store.getters.fcmDetails;
        }
    },
    mounted() {
        this.safariTrue = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        // this.getOpenGuest();
        // this.getSearchCustomer();
        // if(this.summary.customer_email) {
        //     this.summary.customer_email = this.summary.customer_email.replaceAll(/\s/g,'')
        // } else {
        //     this.summary['customer_email'] = ''
        // }
        console.log(this.customer_name)
        this.open_guest.full_name = this.customer_name || 'Open Guest'
    },
    destroyed() {
        EventBus.$off("closePaymentProcess");
    },
    created(){
        const dict = {
            custom: {
                recieptEmail: {
                    required: () => 'Receipt Email is Required',
                    email: () => 'Email should be of Valid Format'
                },
            }
        }
        this.$validator.localize('en', dict)
    },
    watch:{
        
    },
};
</script>
<style scoped>
.pay-method-block {
    background-image: linear-gradient(to bottom, #005dae, #004fe3);
    padding: 12px 8px;
    color: #fff;
    height: 140px;
}
.payment-header-text {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.21;
    text-align: left;
    color: #fff;
    margin-bottom: 12px;
}
.pay-method-block .list-title {
    position: absolute;
    top: -26px;
    left: 0;
    right: 0px;
    margin: 0 auto;
}
.ttl-amount {
    background-color: #fff;
    color: #00448b;
    padding: 6px 16px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 20px;
    width: 220px;
    text-align: center;
    margin: 0 auto;
}
.payment-list-container {
    display: block;
    text-decoration: none;
    border-radius: 7px;
    border: solid 0.5px #ffffff;
    background-color: #0059a5;
}
.payment-list-container .payment-list-media {
    width: 40px;
    position: relative;
    display: table-cell;
    padding: 10px;
    vertical-align: middle;
}
.payment-list-container .payment-list-media img {
    display: block;
    width: 35px;;
    height: 35px;
    text-align: center;
    font-size: 1.125rem;
    line-height: 35px;
}
.payment-list-content {
    color: #fff;
}
.payment-list-container>div {
    display: table-cell;
    padding: .625rem;
    vertical-align: middle;
}
.payment-list-container>div+div {
    padding-left: 0;
}
.payment-list-content h4.payment-title {
    font-size: 14px;
    color: #fff;
    line-height: 1rem;
    font-weight: 600;
    margin: 0;
}
div.payment-desc {
    font-size: 13px;
    line-height: 1rem;
    font-weight: 500;
    margin-top: 10px;
}
div.payment-desc.cashcolor {
    color: #79ffd3;
}
div.payment-desc.cardcolor {
    color: #93caff;
}
div.payment-desc.othercolor {
    color: #ffdea8;
}
.hollow-dots-spinner.spinner-relative {
  position: relative !important;
}
.colorRed{
  color: #db0000;
}
.grandRemanining{
  background-color: #00448b;
  color: #fff;
}
.grandTotal {
  background-color: #00448b!important;
  color: #fff!important;
  border-right: solid 1px #fff;
}
.borderBottom{
  border-bottom: solid 1px #cdd2da;
}
.grandTotalValue{
  padding: 2px 12px;
  border-radius: 15px;
}
.horizontalLine{
  border-top: dashed 2px #94bef0;
}
.DollarTab {
  border: 1px solid #00448b;
  border-right: 2px solid #00448b;
  height: 34px !important;
  color: #00448b;
  background: transparent;
  line-height: 34px !important;
  text-transform: none !important;
  padding: 5px 18px 5px 18px;
  font-size: 14px !important;
  cursor: pointer;
  font-weight: 500;
  border-radius: 20px 0 0 20px !important;
}
.PerTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b;
  background: transparent;
  line-height: 34px !important;
  text-transform: none !important;
  padding: 5px 18px 5px 18px;
  font-size: 14px !important;
  font-weight: 500;
  cursor: pointer;
  border-left: none !important;
  border-radius: 0 20px 20px 0 !important;
  margin-left: -3px;
}
.activeClassAmount {
  background: #00448b !important;
  color: #fff !important;
  font-weight: bold;
}
.resetSettingButton{
  background-image: linear-gradient(to bottom, #1a9347, #1fb155 51%, #4fd882) !important;
  background-color: none !important;
  border-color: none !important;
}
.splitEquallyButton{
  background-image: linear-gradient(to bottom, #f9a00b, #f9a00b 51%, #ffd184) !important;
  background-color: none !important;
  border-color: none !important;
}


.split-uk-grid{
    display:-ms-flexbox;
    display:-webkit-flex;
    display:flex;
    -ms-flex-wrap:wrap;
    -webkit-flex-wrap:wrap;
    flex-wrap:wrap;
    margin:0;
    padding:0;
    list-style:none
}
.split-uk-grid:after,.split-uk-grid:before{
    content:"";
    display:block;
    overflow:hidden
}
.split-uk-grid:after{
    clear:both
}
.split-uk-grid>*{
    -ms-flex:none;
    -webkit-flex:none;
    flex:none;
    margin:0;
    float:left
}
.split-uk-grid>*>:last-child{
    margin-bottom:0
}
.split-uk-grid>*{
    padding-left:25px
}
.split-uk-grid+.split-uk-grid,.split-uk-grid-margin,.split-uk-grid>*>.split-uk-panel+.split-uk-panel{
    margin-top:25px
}
@media (min-width:1220px){
    .split-uk-grid>*{
        padding-left:35px
    }
    .split-uk-grid+.split-uk-grid,.split-uk-grid-margin,.split-uk-grid>*>.split-uk-panel+.split-uk-panel{
        margin-top:35px
    }
}
.split-uk-grid-collapse{
    margin-left:0
}
.split-uk-grid-collapse>*{
    padding-left:0
}
.split-uk-grid-collapse+.split-uk-grid-collapse,.split-uk-grid-collapse>*>.split-uk-panel+.split-uk-panel,.split-uk-grid-collapse>.split-uk-grid-margin{
    margin-top:0
}
.split-uk-grid-small{
    margin-left:-10px
}
.split-uk-grid-small>*{
    padding-left:10px
}
.split-uk-grid-small+.split-uk-grid-small,.split-uk-grid-small>*>.split-uk-panel+.split-uk-panel,.split-uk-grid-small>.split-uk-grid-margin{
    margin-top:10px
}
.split-uk-grid-medium>*{
    padding-left:25px
}
.split-uk-grid-medium+.split-uk-grid-medium,.split-uk-grid-medium>*>.split-uk-panel+.split-uk-panel,.split-uk-grid-medium>.split-uk-grid-margin{
    margin-top:25px
}
@media(max-width:760px){
    .pay-method-block{
        height: auto;
    }
}
@media (min-width:960px){
    .split-uk-grid-large>*{
        padding-left:35px
    }
    .split-uk-grid-large+.split-uk-grid-large,.split-uk-grid-large-margin,.split-uk-grid-large>*>.split-uk-panel+.split-uk-panel{
        margin-top:35px
    }
}
@media (min-width:1220px){
    .split-uk-grid-large{
        margin-left:-50px
    }
    .split-uk-grid-large>*{
        padding-left:50px
    }
    .split-uk-grid-large+.split-uk-grid-large,.split-uk-grid-large-margin,.split-uk-grid-large>*>.split-uk-panel+.split-uk-panel{
        margin-top:50px
    }
}
.split-uk-grid-divider>*{
    padding-left:25px;
    padding-right:25px
}
.split-uk-grid-divider>[class*=split-uk-width-9-]:nth-child(n+2),.split-uk-grid-divider>[class*=split-uk-width-1-]:not(.split-uk-width-1-1):nth-child(n+2),.split-uk-grid-divider>[class*=split-uk-width-2-]:nth-child(n+2),.split-uk-grid-divider>[class*=split-uk-width-3-]:nth-child(n+2),.split-uk-grid-divider>[class*=split-uk-width-4-]:nth-child(n+2),.split-uk-grid-divider>[class*=split-uk-width-5-]:nth-child(n+2),.split-uk-grid-divider>[class*=split-uk-width-6-]:nth-child(n+2),.split-uk-grid-divider>[class*=split-uk-width-7-]:nth-child(n+2),.split-uk-grid-divider>[class*=split-uk-width-8-]:nth-child(n+2){
    border-left:1px solid #ddd
}
@media (min-width:768px){
    .split-uk-grid-divider>[class*=split-uk-width-medium-]:not(.split-uk-width-medium-1-1):nth-child(n+2){
        border-left:1px solid #ddd
    }
}
@media (min-width:960px){
    .split-uk-grid-divider>[class*=split-uk-width-large-]:not(.split-uk-width-large-1-1):nth-child(n+2){
        border-left:1px solid #ddd
    }
}
@media (min-width:1220px){
    .split-uk-grid-divider>*{
        padding-left:35px;
        padding-right:35px
    }
    .split-uk-grid-divider:empty{
        margin-top:35px;
        margin-bottom:35px
    }
}
.split-uk-grid-divider:empty{
    margin-top:25px;
    margin-bottom:25px;
    border-top:1px solid #ddd
}
.split-uk-grid-match>*{
    display:-ms-flexbox;
    display:-webkit-flex;
    display:flex;
    -ms-flex-wrap:wrap;
    -webkit-flex-wrap:wrap;
    flex-wrap:wrap
}
.split-uk-grid-match>*>*{
    -ms-flex:none;
    -webkit-flex:none;
    flex:none;
    box-sizing:border-box;
    width:100%
}
/* [class*=split-uk-grid-width]>*{
    box-sizing:border-box;
    width:100%
} */
.split-uk-grid-width-1-2>*{
    width:50%
}
.split-uk-grid-width-1-3>*{
    width:33.333%
}
.split-uk-grid-width-1-4>*{
    width:25%
}
.split-uk-grid-width-1-5>*{
    width:20%
}
.split-uk-grid-width-1-6>*{
    width:16.666%
}
.split-uk-grid-width-1-10>*{
    width:10%
}
.split-uk-grid-width-auto>*{
    width:auto
}
@media (min-width:480px){
    .split-uk-grid-width-small-1-1>*{
        width:100%
    }
    .split-uk-grid-width-small-1-2>*{
        width:50%
    }
    .split-uk-grid-width-small-1-49>*{
        width:49%
    }
    .split-uk-grid-width-small-1-3>*{
        width:33.333%
    }
    .split-uk-grid-width-small-1-4>*{
        width:25%
    }
    .split-uk-grid-width-small-1-5>*{
        width:20%
    }
    .split-uk-grid-width-small-1-6>*{
        width:16.666%
    }
    .split-uk-grid-width-small-1-10>*{
        width:10%
    }
}
@media (min-width:768px){
    .split-uk-grid-width-medium-1-1>*{
        width:100%
    }
    .split-uk-grid-width-medium-1-2>*{
        width:50%
    }
    .split-uk-grid-width-medium-1-3>*{
        width:33.333%
    }
    .split-uk-grid-width-medium-1-32>*{
        width:32.333%
    }
    .split-uk-grid-width-medium-1-4>*{
        width:25%
    }
    .split-uk-grid-width-medium-1-5>*{
        width:20%
    }
    .split-uk-grid-width-medium-1-6>*{
        width:16.666%
    }
    .split-uk-grid-width-medium-1-10>*{
        width:10%
    }
}
@media (min-width:960px){
    .split-uk-grid-width-large-1-1>*{
        width:100%
    }
    .split-uk-grid-width-large-1-2>*{
        width:50%
    }
    .split-uk-grid-width-large-1-3>*{
        width:33.333%
    }
    .split-uk-grid-width-large-1-31>*{
        width:31.5%
    }
    .split-uk-grid-width-large-1-4>*{
        width:25%
    }
    .split-uk-grid-width-large-1-24>*{
        width:24%
    }
    .split-uk-grid-width-large-1-5>*{
        width:20%
    }
    .split-uk-grid-width-large-1-6>*{
        width:16.666%
    }
    .split-uk-grid-width-large-1-10>*{
        width:10%
    }
}
@media (min-width:1220px){
    .split-uk-grid-width-xlarge-1-1>*{
        width:100%
    }
    .split-uk-grid-width-xlarge-1-2>*{
        width:50%
    }
    .split-uk-grid-width-xlarge-1-3>*{
        width:33.333%
    }
    .split-uk-grid-width-xlarge-1-4>*{
        width:25%
    }
    .split-uk-grid-width-xlarge-1-5>*{
        width:20%
    }
    .split-uk-grid-width-xlarge-1-23>* {
        width: 23%;
    }
    .split-uk-grid-width-xlarge-1-15>*{
        width:15.866%
    }
    .split-uk-grid-width-xlarge-1-6>*{
        width:16.666%
    }
    .split-uk-grid-width-xlarge-1-16>*{
        width:16% !important;
    }
    .split-uk-grid-width-xlarge-1-10>*{
        width:10%
    }
}
[class*=split-uk-width]{
    box-sizing:border-box;
    width:100%
}
.split-uk-width-1-1{
    width:100%
}
.split-uk-width-1-2,.split-uk-width-2-4,.split-uk-width-3-6,.split-uk-width-5-10{
    width:50%
}
.split-uk-width-1-3,.split-uk-width-2-6{
    width:33.333%
}
.split-uk-width-2-3,.split-uk-width-4-6{
    width:66.666%
}
.split-uk-width-1-4{
    width:25%
}
.split-uk-width-3-4{
    width:75%
}
.split-uk-width-1-5,.split-uk-width-2-10{
    width:20%
}
.split-uk-width-2-5,.split-uk-width-4-10{
    width:40%
}
.split-uk-width-3-5,.split-uk-width-6-10{
    width:60%
}
.split-uk-width-4-5,.split-uk-width-8-10{
    width:80%
}
.split-uk-width-1-6{
    width:16.666%
}
.split-uk-width-5-6{
    width:83.333%
}
.split-uk-width-1-10{
    width:10%
}
.split-uk-width-3-10{
    width:30%
}
.split-uk-width-7-10{
    width:70%
}
.split-uk-width-9-10{
    width:90%
}
@media (min-width:480px){
    .split-uk-width-small-1-1{
        width:100%
    }
    .split-uk-width-small-1-2,.split-uk-width-small-2-4,.split-uk-width-small-3-6,.split-uk-width-small-5-10{
        width:50%
    }
    .split-uk-width-small-1-3,.split-uk-width-small-2-6{
        width:33.333%
    }
    .split-uk-width-small-2-3,.split-uk-width-small-4-6{
        width:66.666%
    }
    .split-uk-width-small-1-4{
        width:25%
    }
    .split-uk-width-small-3-4{
        width:75%
    }
    .split-uk-width-small-1-5,.split-uk-width-small-2-10{
        width:20%
    }
    .split-uk-width-small-2-5,.split-uk-width-small-4-10{
        width:40%
    }
    .split-uk-width-small-3-5,.split-uk-width-small-6-10{
        width:60%
    }
    .split-uk-width-small-4-5,.split-uk-width-small-8-10{
        width:80%
    }
    .split-uk-width-small-1-6{
        width:16.666%
    }
    .split-uk-width-small-5-6{
        width:83.333%
    }
    .split-uk-width-small-1-10{
        width:10%
    }
    .split-uk-width-small-3-10{
        width:30%
    }
    .split-uk-width-small-7-10{
        width:70%
    }
    .split-uk-width-small-9-10{
        width:90%
    }
}
@media (min-width:768px){
    .split-uk-width-medium-1-1{
        width:100%
    }
    .split-uk-width-medium-1-2,.split-uk-width-medium-2-4,.split-uk-width-medium-3-6,.split-uk-width-medium-5-10{
        width:50%
    }
    .split-uk-width-medium-1-3,.split-uk-width-medium-2-6{
        width:33.333%
    }
    .split-uk-width-medium-2-3,.split-uk-width-medium-4-6{
        width:66.666%
    }
    .split-uk-width-medium-1-4{
        width:25%
    }
    .split-uk-width-medium-3-4{
        width:75%
    }
    .split-uk-width-medium-1-5,.split-uk-width-medium-2-10{
        width:20%
    }
    .split-uk-width-medium-2-5,.split-uk-width-medium-4-10{
        width:40%
    }
    .split-uk-width-medium-3-5,.split-uk-width-medium-6-10{
        width:60%
    }
    .split-uk-width-medium-4-5,.split-uk-width-medium-8-10{
        width:80%
    }
    .split-uk-width-medium-1-6{
        width:16.666%
    }
    .split-uk-width-medium-5-6{
        width:83.333%
    }
    .split-uk-width-medium-1-10{
        width:10%
    }
    .split-uk-width-medium-3-10{
        width:30%
    }
    .split-uk-width-medium-7-10{
        width:70%
    }
    .split-uk-width-medium-9-10{
        width:90%
    }
}
@media (min-width:960px){
    .split-uk-width-large-1-1{
        width:100%
    }
    .split-uk-width-large-1-2,.split-uk-width-large-2-4,.split-uk-width-large-3-6,.split-uk-width-large-5-10{
        width:50%
    }
    .split-uk-width-large-1-3,.split-uk-width-large-2-6{
        width:33.333%
    }
    .split-uk-width-large-2-3,.split-uk-width-large-4-6{
        width:66.666%
    }
    .split-uk-width-large-1-4{
        width:25%
    }
    .split-uk-width-large-3-4{
        width:75%
    }
    .split-uk-width-large-1-5,.split-uk-width-large-2-10{
        width:20%
    }
    .split-uk-width-large-2-5,.split-uk-width-large-4-10{
        width:40%
    }
    .split-uk-width-large-3-5,.split-uk-width-large-6-10{
        width:60%
    }
    .split-uk-width-large-4-5,.split-uk-width-large-8-10{
        width:80%
    }
    .split-uk-width-large-1-6{
        width:16.666%
    }
    .split-uk-width-large-5-6{
        width:83.333%
    }
    .split-uk-width-large-1-10{
        width:10%
    }
    .split-uk-width-large-3-10{
        width:30%
    }
    .split-uk-width-large-7-10{
        width:70%
    }
    .split-uk-width-large-9-10{
        width:90%
    }
}
@media (min-width:1220px){
    .split-uk-width-xlarge-1-1{
        width:100%
    }
    .split-uk-width-xlarge-1-2,.split-uk-width-xlarge-2-4,.split-uk-width-xlarge-3-6,.split-uk-width-xlarge-5-10{
        width:50%
    }
    .split-uk-width-xlarge-1-3,.split-uk-width-xlarge-2-6{
        width:33.333%
    }
    .split-uk-width-xlarge-2-3,.split-uk-width-xlarge-4-6{
        width:66.666%
    }
    .split-uk-width-xlarge-1-4{
        width:25%
    }
    .split-uk-width-xlarge-3-4{
        width:75%
    }
    .split-uk-width-xlarge-1-5,.split-uk-width-xlarge-2-10{
        width:20%
    }
    .split-uk-width-xlarge-2-5,.split-uk-width-xlarge-4-10{
        width:40%
    }
    .split-uk-width-xlarge-3-5,.split-uk-width-xlarge-6-10{
        width:60%
    }
    .split-uk-width-xlarge-4-5,.split-uk-width-xlarge-8-10{
        width:80%
    }
    .split-uk-width-xlarge-1-6{
        width:16.666%
    }
    .split-uk-width-xlarge-5-6{
        width:83.333%
    }
    .split-uk-width-xlarge-1-10{
        width:10%
    }
    .split-uk-width-xlarge-3-10{
        width:30%
    }
    .split-uk-width-xlarge-7-10{
        width:70%
    }
    .split-uk-width-xlarge-9-10{
        width:90%
    }
}
@media (min-width:768px){
    [class*=split-uk-push-],[class*=split-uk-pull-]{
        position:relative
    }
    .split-uk-push-1-2,.split-uk-push-2-4,.split-uk-push-3-6,.split-uk-push-5-10{
        left:50%
    }
    .split-uk-push-1-3,.split-uk-push-2-6{
        left:33.333%
    }
    .split-uk-push-2-3,.split-uk-push-4-6{
        left:66.666%
    }
    .split-uk-push-1-4{
        left:25%
    }
    .split-uk-push-3-4{
        left:75%
    }
    .split-uk-push-1-5,.split-uk-push-2-10{
        left:20%
    }
    .split-uk-push-2-5,.split-uk-push-4-10{
        left:40%
    }
    .split-uk-push-3-5,.split-uk-push-6-10{
        left:60%
    }
    .split-uk-push-4-5,.split-uk-push-8-10{
        left:80%
    }
    .split-uk-push-1-6{
        left:16.666%
    }
    .split-uk-push-5-6{
        left:83.333%
    }
    .split-uk-push-1-10{
        left:10%
    }
    .split-uk-push-3-10{
        left:30%
    }
    .split-uk-push-7-10{
        left:70%
    }
    .split-uk-push-9-10{
        left:90%
    }
    .split-uk-pull-1-2,.split-uk-pull-2-4,.split-uk-pull-3-6,.split-uk-pull-5-10{
        left:-50%
    }
    .split-uk-pull-1-3,.split-uk-pull-2-6{
        left:-33.333%
    }
    .split-uk-pull-2-3,.split-uk-pull-4-6{
        left:-66.666%
    }
    .split-uk-pull-1-4{
        left:-25%
    }
    .split-uk-pull-3-4{
        left:-75%
    }
    .split-uk-pull-1-5,.split-uk-pull-2-10{
        left:-20%
    }
    .split-uk-pull-2-5,.split-uk-pull-4-10{
        left:-40%
    }
    .split-uk-pull-3-5,.split-uk-pull-6-10{
        left:-60%
    }
    .split-uk-pull-4-5,.split-uk-pull-8-10{
        left:-80%
    }
    .split-uk-pull-1-6{
        left:-16.666%
    }
    .split-uk-pull-5-6{
        left:-83.333%
    }
    .split-uk-pull-1-10{
        left:-10%
    }
    .split-uk-pull-3-10{
        left:-30%
    }
    .split-uk-pull-7-10{
        left:-70%
    }
    .split-uk-pull-9-10{
        left:-90%
    }
}
.split-uk-panel{
    display:block;
    position:relative
}
.split-uk-panel,.split-uk-panel:hover{
    text-decoration:none
}
.split-uk-panel:after,.split-uk-panel:before{
    content:"";
    display:table
}
.split-uk-panel:after{
    clear:both
}
.split-uk-panel>:not(.split-uk-panel-title):last-child{
    margin-bottom:0
}
.split-uk-panel-title{
    margin-top:0;
    margin-bottom:15px;
    font-size:18px;
    line-height:24px;
    font-weight:400;
    text-transform:none;
    color:#444
}
.split-uk-panel-badge{
    position:absolute;
    top:0;
    right:0;
    z-index:1
}
.split-uk-panel-teaser{
    margin-bottom:15px
}
.split-uk-panel-body{
    padding:15px
}
.split-uk-panel-box{
    padding:15px;
    background:#fafafa;
    color:#444;
    border:1px solid #ddd;
    border-radius:4px
}
.split-uk-panel-box-hover:hover{
    color:#444
}
.split-uk-panel-box .split-uk-panel-title{
    color:#444
}
.split-uk-panel-box .split-uk-panel-badge{
    top:10px;
    right:10px
}
.split-uk-panel-box>.split-uk-panel-teaser{
    margin-top:-16px;
    margin-left:-16px;
    margin-right:-16px
}
.split-uk-panel-box>.split-uk-nav-side{
    margin:0 -15px
}
.split-uk-panel-box-primary{
    background-color:#ebf7fd;
    color:#2d7091;
    border-color:rgba(45,112,145,.3)
}
.split-uk-panel-box-primary-hover:hover{
    color:#2d7091
}
.split-uk-panel-box-primary .split-uk-panel-title{
    color:#2d7091
}
.split-uk-panel-box-secondary{
    background-color:#fff;
    color:#444
}
.split-uk-panel-box-secondary-hover:hover{
    color:#444
}
.split-uk-panel-box-secondary .split-uk-panel-title{
    color:#444
}
.split-uk-panel-hover{
    padding:15px;
    color:#444;
    border:1px solid transparent;
    border-radius:4px
}
.split-uk-panel-hover:hover{
    background:#fafafa;
    color:#444;
    border-color:#ddd
}
.split-uk-panel-hover .split-uk-panel-badge{
    top:10px;
    right:10px
}
.split-uk-panel-hover>.split-uk-panel-teaser{
    margin-top:-16px;
    margin-left:-16px;
    margin-right:-16px
}
.split-uk-panel-header .split-uk-panel-title{
    padding-bottom:10px;
    border-bottom:1px solid #ddd;
    color:#444
}
.split-uk-panel-space{
    padding:30px
}
.split-uk-panel-space .split-uk-panel-badge{
    top:30px;
    right:30px
}
.split-uk-panel+.split-uk-panel-divider{
    margin-top:50px!important
}
.split-uk-panel+.split-uk-panel-divider:before{
    content:"";
    display:block;
    position:absolute;
    top:-25px;
    left:0;
    right:0;
    border-top:1px solid #ddd
}
@media (min-width:1220px){
    .split-uk-panel+.split-uk-panel-divider{
        margin-top:70px!important
    }
    .split-uk-panel+.split-uk-panel-divider:before{
        top:-35px
    }
}
.split-uk-panel-box .split-uk-panel-teaser{
    border-top-left-radius:4px;
    border-top-right-radius:4px;
    overflow:hidden;
    -webkit-transform:translateZ(0)
}
.split-uk-block{
    position:relative;
    box-sizing:border-box;
    padding-top:20px;
    padding-bottom:20px
}
@media (min-width:768px){
    .split-uk-block{
        padding-top:50px;
        padding-bottom:50px
    }
}
.split-uk-block:after,.split-uk-block:before{
    content:"";
    display:table
}
.split-uk-block:after{
    clear:both
}
.split-uk-block>:last-child{
    margin-bottom:0
}
.split-uk-block-large{
    padding-top:20px;
    padding-bottom:20px
}
@media (min-width:768px){
    .split-uk-block-large{
        padding-top:50px;
        padding-bottom:50px
    }
}
@media (min-width:960px){
    .split-uk-block-large{
        padding-top:100px;
        padding-bottom:100px
    }
}
.split-uk-block-default{
    background:#fff
}
.split-uk-block-muted{
    background:#f9f9f9
}
.split-uk-block-primary{
    background:#00a8e6
}
.split-uk-block-secondary{
    background:#222
}
.split-uk-block-default+.split-uk-block-default,.split-uk-block-muted+.split-uk-block-muted,.split-uk-block-primary+.split-uk-block-primary,.split-uk-block-secondary+.split-uk-block-secondary{
    padding-top:0
}
.md-card.dashboarddetailswidget-card{
    position:relative;
    border-radius: 6px;
    box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(140deg, #005dae -9%, #004fe3 95%);
    cursor: pointer;
}
.md-card.dashboarddetailswidget-card+.md-card.dashboarddetailswidget-card,.md-card.dashboarddetailswidget-card+.split-uk-grid,.split-uk-grid+.md-card.dashboarddetailswidget-card{
    margin-top:25px
}
.md-card.dashboarddetailswidget-card .full_width_in_card{
    padding:16px 24px;
    background:rgba(0,0,0,.085)
}
.md-card.dashboarddetailswidget-card .md-card-toolbar{
    height:48px;
    padding:0 16px;
    border-bottom:1px solid rgba(0,0,0,.12);
    background:#fff
}
.md-card.dashboarddetailswidget-card .md-card-toolbar:after,.md-card.dashboarddetailswidget-card .md-card-toolbar:before{
    content:" ";
    display:table
}
.md-card.dashboarddetailswidget-card .md-card-toolbar:after{
    clear:both
}
.md-card.dashboarddetailswidget-card .md-card-toolbar:after,.md-card.dashboarddetailswidget-card .md-card-toolbar:before{
    content:" ";
    display:table
}
.md-card.dashboarddetailswidget-card .md-card-toolbar:after{
    clear:both
}
.md-card.dashboarddetailswidget-card .md-card-toolbar-heading-text{
    font:500 14px/50px Roboto,sans-serif;
    color:#212121;
    margin:0;
    float:left;
    overflow:hidden;
    height:48px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar-heading-text.large{
    font-size:18px;
    font-weight:400
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-toggle-group{
    float:left;
    margin:8px 0 0 16px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-toggle-group.md-toggle-group-small{
    margin-top:10px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions{
    float:right;
    padding-top:10px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions .split-uk-open .md-card-toolbar-icon{
    background:rgba(0,0,0,.085);
    color:#212121
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions .md-card-dropdown{
    display:inline-block;
    position:relative
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions .selectize-control{
    min-width:220px;
    margin-top:-3px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions .selectize-control .selectize-input{
    min-height:30px;
    padding:4px 8px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions .selectize-control .selectize-dropdown{
    margin-top:-34px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-icon+.md-card-dropdown{
    margin-left:4px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-fullscreen-deactivate{
    margin:9px 8px 0 0
}
.md-card.dashboarddetailswidget-card .md-card-toolbar-input{
    border:none;
    font:400 18px/24px Roboto,sans-serif;
    height:auto;
    background:0 0!important;
    padding:12px 0;
    width:50%;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .split-uk-tab{
    margin-top:-2px;
    border-bottom:none
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .split-uk-tab li>a{
    padding:10px 8px!important
}
.md-card.dashboarddetailswidget-card .md-card-head{
    height:125px;
    position:relative;
    border-bottom:1px solid transparent
}
.md-card.dashboarddetailswidget-card .md-card-head-menu{
    position:absolute;
    right:8px;
    top:8px;
    border: 1px solid #e0e3e6;
    border-radius: 4px;
    background:#fff;
}
.img-div {
    background-color: rgba(255, 255, 255, 0);
    width: 86px;
    height: 86px;
    border-radius: 50%;
    margin-top: 20px;
    display: inline-block;
}
.md-card.dashboarddetailswidget-card .md-card-head-avatar{
    width:75px;
    height:75px;
    border-radius:50%;
    margin-top:4px;
    display:inline-block
}
.md-card.dashboarddetailswidget-card .md-card-head-text{
    padding:10px 5px;
    font-size: 16px;
    color:#fff;
    margin:0;
    font-weight: 600;
    line-height: 1.19;
}
.md-card.dashboarddetailswidget-card .md-card-head-text span:not(.listNavSelector){
    display:block;
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
    line-height: 1.25;
}
.md-card.dashboarddetailswidget-card .md-card-head-text.text_dark{
    color: #303031 !important
}
.md-card.dashboarddetailswidget-card .md-card-head-text-over{
    background-image:-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.65)),to(rgba(0,0,0,0)));
    background-image:linear-gradient(to bottom,rgba(0,0,0,.65) 0,rgba(0,0,0,0) 100%)
}
.md-card.dashboarddetailswidget-card .md-card-head-subtext{
    position:absolute;
    bottom:10px;
    left:0;
    right:0;
    padding:0 16px;
    text-align:right;
    color:#fff
}
.md-card.dashboarddetailswidget-card .md-card-head-subtext span{
    font-size:26px
}
.md-card.dashboarddetailswidget-card .md-card-head-icon{
    font-size:48px;
    color:#fff;
    vertical-align:middle
}
.md-card.dashboarddetailswidget-card .md-card-head.head_background{
    background-repeat:no-repeat;
    background-position:center center;
    background-size:cover;
    border-bottom-color:transparent
}
.md-card.dashboarddetailswidget-card .md-card-head.head_background .md-card-head-text{
    color:#fff
}
.md-card.dashboarddetailswidget-card .md-card-head.head_background_top{
    background-repeat:no-repeat;
    background-position:center top
}
.md-card.dashboarddetailswidget-card .md-card-head.head_background_bottom{
    background-repeat:no-repeat;
    background-position:center bottom
}
.md-card.dashboarddetailswidget-card .md-card-head .head_chart{
    height:100px;
    width:100%;
    position:absolute!important;
    left:0;
    top:40px
}
.md-card.dashboarddetailswidget-card .md-card-head .fitVid_player{
    width:100%;
    height:160px;
    overflow:hidden
}
.md-card.dashboarddetailswidget-card .md-card-head-img{
    height:100%;
    width: auto;
}
.md-card.dashboarddetailswidget-card .md-card-head iframe{
    height:160px
}
.md-card.dashboarddetailswidget-card .md-card-content{
    padding: 5px 12px 5px;
}
.md-card.dashboarddetailswidget-card .md-card-content.padding-reset{
    padding:0
}
.md-card.dashboarddetailswidget-card .md-card-content.large-padding{
    padding:24px 35px
}
.md-card.dashboarddetailswidget-card .md-card-content.small-padding{
    padding:8px
}
.md-card.md-card-fullscreen-activate{
    cursor:pointer
}
.md-card.md-card-fullscreen{
    position:fixed;
    z-index:9998;
    overflow-x:hidden;
    cursor:default
}
.md-card.md-card-fullscreen .md-card-fullscreen-activate{
    display:none
}
.md-card.md-card-fullscreen .md-card-fullscreen-hide{
    display:none
}
.md-card.dashboarddetailswidget-card .md-card-fullscreen-content{
    display:none;
    padding:16px
}
.md-card-fullscreen-content-hidden .md-card.dashboarddetailswidget-card .md-card-fullscreen-content{
    display:block;
    visibility:hidden;
    padding:0
}
.md-card.mdToolbar_fixed{
    overflow-y:hidden
}
.md-card.mdToolbar_fixed>.md-card-toolbar{
    position:fixed;
    left:0;
    right:0;
    top:0;
    z-index:9999;
    -webkit-box-shadow:0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23);
    box-shadow:0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23)
}
.md-card.mdToolbar_fixed>.md-card-content{
    overflow-y:scroll;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    position:absolute;
    width:100%;
    top:48px;
    bottom:0
}
.md-card.mdToolbar_fixed>.md-card-content .md-card-fullscreen-content{
    padding:16px 0
}
.md-card.dashboarddetailswidget-card.md-card-overlay{
    overflow:hidden;
    padding-bottom:54px
}
.md-card.dashboarddetailswidget-card .md-card-overlay .md-card-content{
    height:142px;
    overflow:hidden;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.md-card.dashboarddetailswidget-card .md-card-overlay .md-card-content.no_truncate{
    position:relative
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-content.no_truncate:after{
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    height:8px;
    background-image:-webkit-gradient(linear,left bottom,left top,color-stop(50%,#fff),to(rgba(255,255,255,0)));
    background-image:linear-gradient(to top,#fff 50%,rgba(255,255,255,0) 100%);
    display:block;
    content:'';
    z-index:10
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-content>pre{
    margin-top:0;
    max-height:110px
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-content>pre>code{
    overflow:hidden
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-content{
    position:absolute;
    top:100%;
    left:0;
    right:0;
    padding:0 16px;
    margin-top:-54px;
    border-top:1px solid rgba(0,0,0,.12);
    text-align:left;
    bottom:0;
    background:#fff;
    z-index:10;
    -webkit-transition:all 280ms cubic-bezier(.4,0,.2,1);
    transition:all 280ms cubic-bezier(.4,0,.2,1)
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-content p{
    margin:0
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-content p+*{
    margin-top:16px
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-content p+p{
    margin-top:4px
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header{
    padding:12px 0
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:after,.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:before{
    content:" ";
    display:table
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:after{
    clear:both
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:after,.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:before{
    content:" ";
    display:table
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:after{
    clear:both
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header h3,.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header h4{
    margin:0;
    text-overflow:ellipsis;
    display:inline-block;
    vertical-align:top;
    white-space:nowrap;
    overflow:hidden;
    width:100%;
    padding-right:32px;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header .md-icon{
    position:absolute;
    right:12px;
    top:11px
}
.md-card.dashboarddetailswidget-card.md-card-overlay-active .md-card-overlay-content{
    top:-1px;
    margin-top:0
}
.split-uk-sortable-dragged .md-card{
    -webkit-box-shadow:0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23);
    box-shadow:0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23)
}
.split-uk-sortable-dragged .md-card.dashboarddetailswidget-card canvas{
    margin:0 auto;
    display:block
}
.md-card.dashboarddetailswidget-card .heading_list{
    padding:0
}
.md-card-danger,.md-card-primary,.md-card-success,.md-card-warning{
    border-left:4px solid transparent
}
.md-card-primary{
    border-left-color:#00448b
}
.md-card-success{
    border-left-color:#7cb342
}
.md-card-danger{
    border-left-color:#e53935
}
.md-card-warning{
    border-left-color:#ffa000
}
.md-card-horizontal{
    position:relative;
    min-height:200px
}
.md-card-horizontal:after,.md-card-horizontal:before{
    content:" ";
    display:table
}
.md-card-horizontal:after{
    clear:both
}
.md-card-horizontal:after,.md-card-horizontal:before{
    content:" ";
    display:table
}
.md-card-horizontal:after{
    clear:both
}
.md-card-horizontal-grid{
    min-height:auto
}
.md-card-horizontal .md-card-head{
    border-right:1px solid rgba(0,0,0,.12);
    border-bottom:none;
    width:200px;
    height:100%;
    position:absolute;
    left:0;
    top:0;
    bottom:0
}
@media only screen and (max-width:767px){
    .md-card-horizontal .md-card-head{
        width:140px
    }
}
.md-card-horizontal .md-card-head-grid{
    border-right:1px solid rgba(0,0,0,.12)
}
.md-card-horizontal .md-card-head-menu{
    right:auto;
    left:8px
}
.md-card-horizontal .md-card-content{
    margin-left:200px
}
@media only screen and (max-width:767px){
    .md-card-horizontal .md-card-content{
        margin-left:140px
    }
}
.md-card-horizontal .md-card-content-grid{
    padding:16px
}
.md-expand,.md-expand-group>*{
    opacity:0
}
.md-card-placeholder{
    min-width:100%
}
.md-card-list-wrapper,.md-card-list-wrapper *,.md-card-list-wrapper :after,.md-card-list-wrapper :before,.md-card-list-wrapper:after,.md-card-list-wrapper:before{
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.md-card-list-wrapper .md-card-list-header{
    position:absolute;
    top:-24px;
    left:0
}
.md-card-list-wrapper .md-card-list{
    margin:48px 0 0 0;
    position:relative
}
.md-card-list-wrapper .md-card-list:first-child{
    margin-top:24px
}
.md-card-list-wrapper .md-card-list>ul{
    margin:0;
    padding:0;
    list-style:none
}
.md-card-list-wrapper .md-card-list>ul>li{
    padding:0;
    margin:0;
    list-style:none
}
.md-card-list-wrapper .md-card-list>ul>li{
    min-height:34px;
    padding:8px 16px;
    font-size:13px;
    -webkit-transition:background 150ms,padding .2s;
    transition:background 150ms,padding .2s;
    background:#fff;
    -webkit-box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);
    box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24)
}
.md-card-list-wrapper .md-card-list>ul>li:after,.md-card-list-wrapper .md-card-list>ul>li:before{
    content:" ";
    display:table
}
.md-card-list-wrapper .md-card-list>ul>li:after{
    clear:both
}
.md-card-list-wrapper .md-card-list>ul>li:after,.md-card-list-wrapper .md-card-list>ul>li:before{
    content:" ";
    display:table
}
.md-card-list-wrapper .md-card-list>ul>li:after{
    clear:both
}
.md-card-list-wrapper .md-card-list>ul>li.item-shown{
    background:#fff;
    padding:8px 36px
}
@media only screen and (max-width:767px){
    .md-card-list-wrapper .md-card-list>ul>li.item-shown .md-card-list-item-subject{
        clear:both;
        float:none;
        padding-top:16px
    }
    .md-card-list-wrapper .md-card-list>ul>li.item-shown .md-card-list-item-subject>span{
        white-space:normal
    }
}
.md-card-list-wrapper .md-card-list>ul>li.item-shown .md-card-list-item-sender{
    width:auto;
    overflow:hidden
}
.md-card-list-wrapper .md-card-list>ul>li.item-shown.md-card-list-item-selected{
    position:relative
}
.md-card-list-wrapper .md-card-list>ul>li.item-shown.md-card-list-item-selected:before{
    content:'';
    position:absolute;
    display:block;
    left:0;
    top:0;
    bottom:0;
    width:8px;
    background:#e3f2fd
}
.md-card-list-wrapper .md-card-list .md-card-list-item-avatar-wrapper,.md-card-list-wrapper .md-card-list .md-card-list-item-select,.md-card-list-wrapper .md-card-list .md-card-list-item-sender{
    float:left
}
.md-card-list-wrapper .md-card-list .md-card-list-item-select{
    padding:6px 8px 0 0
}
.md-card-list-wrapper .md-card-list .md-card-list-item-avatar-wrapper,.md-card-list-wrapper .md-card-list .md-card-list-item-date,.md-card-list-wrapper .md-card-list .md-card-list-item-sender,.md-card-list-wrapper .md-card-list .md-card-list-item-subject{
    padding:0 8px
}
.md-card-list-wrapper .md-card-list .md-card-list-item-avatar-wrapper .md-card-list-item-avatar{
    background:#757575;
    color:#fff;
    width:34px;
    border-radius:50%;
    display:block
}
.md-card-list-wrapper .md-card-list .md-card-list-item-avatar-wrapper .md-card-list-item-avatar-large{
    width:82px;
    border-radius:50%
}
.md-card-list-wrapper .md-card-list .md-card-list-item-avatar-wrapper span.md-card-list-item-avatar{
    line-height:34px;
    text-transform:uppercase;
    text-align:center
}
.md-card-list-wrapper .md-card-list .md-card-list-item-sender{
    width:220px;
    line-height:34px
}
.md-card-list-wrapper .md-card-list .md-card-list-item-sender>span{
    text-overflow:ellipsis;
    display:inline-block;
    vertical-align:top;
    white-space:nowrap;
    overflow:hidden;
    width:100%
}
@media only screen and (max-width:1219px){
    .md-card-list-wrapper .md-card-list .md-card-list-item-sender{
        display:none
    }
}
.md-card-list-wrapper .md-card-list .md-card-list-item-subject{
    overflow:hidden;
    font-weight:500
}
.md-card-list-wrapper .md-card-list .md-card-list-item-subject>span{
    line-height:34px;
    text-overflow:ellipsis;
    display:inline-block;
    vertical-align:top;
    white-space:nowrap;
    overflow:hidden;
    width:100%
}
.md-card-list-wrapper .md-card-list .md-card-list-item-subject .md-card-list-item-sender-small{
    display:none
}
@media only screen and (max-width:1219px){
    .md-card-list-wrapper .md-card-list .md-card-list-item-subject .md-card-list-item-sender-small{
        display:block
    }
    .md-card-list-wrapper .md-card-list .md-card-list-item-subject .md-card-list-item-sender-small+span{
        line-height:inherit
    }
}
.md-card-list-wrapper .md-card-list .md-card-list-item-subject .md-card-list-item-sender-small>span{
    text-overflow:ellipsis;
    display:inline-block;
    vertical-align:top;
    white-space:nowrap;
    overflow:hidden;
    width:100%;
    font-size:12px;
    color:#999
}
.md-card-list-wrapper .md-card-list .md-card-list-item-date{
    line-height:34px;
    float:right;
    color:#999
}
@media only screen and (max-width:479px){
    .md-card-list-wrapper .md-card-list .md-card-list-item-date{
        display:none
    }
}
.md-card-list-wrapper .md-card-list .md-card-list-item-menu{
    float:right;
    margin:0 0 0 8px;
    position:relative
}
.md-card-list-wrapper .md-card-list .md-card-list-item-menu .split-uk-dropdown .material-icons{
    margin-right:8px
}
.md-card-list-wrapper .md-card-list .md-card-list-item-content-wrapper{
    display:none;
    clear:both;
    opacity:0
}
.md-card-list-wrapper .md-card-list .md-card-list-item-content{
    padding:16px 16px 0 0;
    max-height:360px;
    overflow-x:hidden;
    margin:0 0 40px;
    top:20px;
    position:relative;
    font-size:14px
}
.md-card-list-wrapper .md-card-list .md-card-list-item-content+.md-card-list-item-reply{
    padding-top:10px
}
.md-card-list-wrapper .md-card-list .md-card-list-item-reply{
    padding:16px 0
}
.md-card-list-wrapper .md-card-list .md-card-list-item-selected{
    background:#e3f2fd
}
.md-list{
    margin:0;
    padding:0;
    list-style:none
}
.md-list>li{
    padding:0;
    margin:0;
    list-style:none
}
.md-list,.md-list *,.md-list :after,.md-list :before,.md-list:after,.md-list:before{
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.md-list .split-uk-nestable-list>li,.md-list>li{
    min-height:48px;
    padding:8px 4px;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    border-bottom:1px solid rgba(0,0,0,.12);
    position:relative
}
.md-list .split-uk-nestable-list>li>.md-list-content,.md-list>li>.md-list-content{
    overflow:hidden
}
.md-list .split-uk-nestable-list>li>.md-list-content>span,.md-list>li>.md-list-content>span{
    display:block
}
.md-list .split-uk-nestable-list>li>.md-list-content .md-list-heading,.md-list>li>.md-list-content .md-list-heading{
    margin:0;
    font-weight:500;
    display:block;
    overflow:hidden;
    padding-bottom:1px
}
.md-list .split-uk-nestable-list>li>.md-list-content .md-list-menu,.md-list>li>.md-list-content .md-list-menu{
    float:right
}
.md-list .split-uk-nestable-list>li>.md-list-content .md-list-menu .md-list-menu-toggle,.md-list>li>.md-list-content .md-list-menu .md-list-menu-toggle{
    display:block;
    font-size:18px;
    color:rgba(0,0,0,.8);
    width:28px;
    height:28px;
    line-height:28px;
    border-radius:14px;
    text-align:center
}
.md-list .split-uk-nestable-list>li>.md-list-content .split-uk-badge,.md-list>li>.md-list-content .split-uk-badge{
    float:right;
    color:#fff!important
}
.md-list .split-uk-nestable-list>li>.md-list-content .md-list-action,.md-list>li>.md-list-content .md-list-action{
    float:right;
    margin-left:8px;
    margin-top:2px;
    display:none
}
.md-list .split-uk-nestable-list>li>.md-list-content .md-list-action-placeholder,.md-list>li>.md-list-content .md-list-action-placeholder{
    float:right;
    margin-left:8px;
    display:none;
    width:32px;
    height:32px
}
.md-list .split-uk-nestable-list>li .md-list-action-dropdown,.md-list>li .md-list-action-dropdown{
    position:absolute;
    right:16px;
    top:10px;
    display:none
}
.md-list .split-uk-nestable-list>li>a.md-list-content,.md-list>li>a.md-list-content{
    display:block;
    color:#212121
}
.md-list .split-uk-nestable-list>li:last-child,.md-list>li:last-child{
    border-bottom:none
}
.md-list .split-uk-nestable-list>li.md-list-item-active,.md-list>li.md-list-item-active{
    color:#7cb342;
    background:#ededed
}
.md-list .split-uk-nestable-list>li.md-list-item-disabled>.md-list-content,.md-list>li.md-list-item-disabled>.md-list-content{
    color:#bdbdbd
}
.md-list .split-uk-nestable-list>li.md-list-item-disabled>.md-list-content span,.md-list>li.md-list-item-disabled>.md-list-content span{
    color:#bdbdbd!important
}
.md-list .split-uk-nestable-list>li.heading_list,.md-list>li.heading_list{
    min-height:32px;
    padding:32px 8px 16px;
    border-bottom:none;
    background:0 0!important;
    text-transform:uppercase
}
.md-list .split-uk-nestable-list>li:hover>.md-list-addon-element .split-uk-nestable-handle,.md-list>li:hover>.md-list-addon-element .split-uk-nestable-handle,.split-uk-touch .md-list .split-uk-nestable-list>li>.md-list-addon-element .split-uk-nestable-handle,.split-uk-touch .md-list>li>.md-list-addon-element .split-uk-nestable-handle{
    display:block
}
.md-list .split-uk-nestable-list>li:hover>.md-list-content .md-list-action,.md-list .split-uk-nestable-list>li:hover>.md-list-content .md-list-action-placeholder,.md-list>li:hover>.md-list-content .md-list-action,.md-list>li:hover>.md-list-content .md-list-action-placeholder,.split-uk-touch .md-list .split-uk-nestable-list>li>.md-list-content .md-list-action,.split-uk-touch .md-list .split-uk-nestable-list>li>.md-list-content .md-list-action-placeholder,.split-uk-touch .md-list>li>.md-list-content .md-list-action,.split-uk-touch .md-list>li>.md-list-content .md-list-action-placeholder{
    display:block
}
.md-list .split-uk-nestable-list>li:hover .md-list-action-dropdown,.md-list>li:hover .md-list-action-dropdown,.split-uk-touch .md-list .split-uk-nestable-list>li .md-list-action-dropdown,.split-uk-touch .md-list>li .md-list-action-dropdown{
    display:block
}
.md-list .split-uk-nestable-list>li{
    margin-left:64px
}
.md-list-addon>li{
    margin-left:64px;
    position:relative
}
.md-list-addon>li:last-child .md-list-addon-element{
    border-bottom:none;
    bottom:0
}
.md-list-addon>li:first-child .md-list-addon-element{
    top:0
}
.md-list-addon>li.md-list-item-active .md-list-addon-element,.md-list-addon>li.md-list-item-active .md-list-addon-element .material-icons{
    color:#7cb342
}
.md-list-addon-element{
    position:absolute;
    left:-64px;
    top:-1px;
    bottom:-1px;
    width:64px;
    text-align:center;
    padding:8px 0;
    display:block
}
.md-list-addon-element .element-status{
    position:absolute;
    right:12px;
    top:10px;
    width:12px;
    height:12px;
    border-radius:50%;
    background:#bdbdbd;
    border:1px solid #fff
}
.md-list-addon-element .element-status-danger{
    background:#e53935
}
.md-list-addon-element .element-status-success{
    background:#7cb342
}
.md-list-addon-element .element-status-warning{
    background:#ffa000
}
.md-list-addon-element>.md-list-addon-avatar{
    margin-top:2px
}
.md-list-addon-element>.md-list-addon-icon{
    font-size:28px;
    margin-top:4px;
    color:#727272
}
.md-list-addon-element>.material-icons{
    margin-top:6px
}
.md-list-addon-element .icheckbox_md,.md-list-addon-element .iradio_md{
    margin-top:10px
}
.md-list-addon-element .split-uk-nestable-handle{
    position:absolute;
    left:-2px;
    top:12px;
    display:none
}
.md-list-interactive li{
    cursor:pointer
}
.md-list-bg{
    background:#fff
}
.md-list-separated li{
    background:#fff;
    padding:8px
}
.md-list-separated li+li{
    border-top:none;
    margin-top:8px
}
.md-list-bg-no-sep{
    background:#fff;
    padding:8px
}
.md-list-bg-no-sep li>.md-list-content{
    padding:0 4px
}
.md-list-outside>li{
    padding:0
}
.md-list-outside>li>.md-list-content{
    padding:8px 16px;
    display:block;
    color:#212121
}
.md-list-outside>li.md-list-item-active,.md-list-outside>li:hover:not(.heading_list){
    background:rgba(0,0,0,.085)
}
.md-list-outside>li.heading_list{
    padding:32px 16px 16px
}
.md-list-outside.md-list-addon li{
    margin-left:0
}
.md-list-outside.md-list-addon li .md-list-addon-element{
    position:relative;
    top:auto;
    left:auto;
    float:left
}
.md-list-outside.md-list-addon li .md-list-content{
    padding-left:0
}
.md-list-outside-wrapper{
    position:relative
}
.md-list-outside-wrapper .md-list-outside-search{
    position:absolute;
    top:-46px;
    left:16px;
    right:16px;
    z-index:1000
}
.md-list-outside-wrapper .md-list-outside-search .md-input-wrapper{
    padding:0
}
.md-list-outside-wrapper .md-list-outside-search .md-input-wrapper .md-input-bar:after,.md-list-outside-wrapper .md-list-outside-search .md-input-wrapper .md-input-bar:before{
    background:#fff
}
.md-list-outside-wrapper .md-list-outside-search .md-input{
    border-bottom-color:transparent!important
}
.md-list .split-uk-nestable-list{
    padding-left:0
}
.md-list .split-uk-nestable-list .split-uk-nestable-item{
    padding-right:0
}
.md-list .split-uk-nestable-item+.split-uk-nestable-item{
    margin-top:0
}
.md-list-right.md-list-addon>li{
    margin-left:0;
    margin-right:64px
}
.md-list-right.md-list-addon>li .md-list-addon-element{
    left:auto;
    right:-64px
}
.split-uk-touch .md-list-addon-element .split-uk-nestable-handle{
    display:block!important
}
.split-uk-touch .md-list-content .md-list-action,.split-uk-touch .md-list-content .md-list-action-placeholder{
    display:block!important
}
.split-uk-touch .md-list-action-dropdown{
    display:block!important
}

.paymentcard-box {
    box-sizing: border-box;
    padding-left: 10px;
    padding-bottom: 20px;
    display: inline-block;
}
.des-box {
    border-radius: 5px;
    padding: 5px 5px;
    color: #43425d !important;
    font-size: 13px;
    font-weight: 500;
}
.pink-box {
    box-shadow: 0 2px 10px 0 rgba(255, 38, 138, 0.55);
    background-color: #ff268a;
}
.blue-box {
    box-shadow: 0 2px 10px 0 rgba(13, 132, 242, 0.36);
    background-color: #00448b;
}
.lightblue-box {
    box-shadow: 0 2px 10px 0 rgba(13, 132, 242, 0.36);
    background-color: #50bcff;
}
.orange-box {
    box-shadow: 0 2px 10px 0 rgba(245, 166, 35, 0.35);
    background-color: #f5a623;
}
.border-orange1 {
    border-color: #ff7f00 !important;
}
.border-green1 {
    border-color: #1a9347 !important;
}
.border-red1 {
    border-color: #e82828 !important;
}
.card-group {
    margin-bottom: 2.143rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
}
.card-block {
    position: relative;
    padding: 1.429rem;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}
.card.card-block {
    box-shadow: none;
}
@media (min-width: 480px) {

    .card-group {
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }
    .card-group .card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group .card {
        margin-bottom: 0;
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 0%;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    }
    .disable-state{
        pointer-events: none;
        cursor: not-allowed;
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
        color:grey
    }
}
</style>
